import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import LevelEditor from './components/leveleditor/LevelEditor';

const urlParams = new URLSearchParams(window.location.search);
const useLevelEditor = !!urlParams.has('edit');;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    {useLevelEditor && (
      <LevelEditor />
    )}
    {!useLevelEditor && (
      <App />
    )}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
