import Updatable from '../updatable';
import Player from '../player/player';

export default class Scene extends Updatable {

    player = new Player();

    constructor(sceneName) {
        super();
        this.sceneData = require(`./${sceneName}.json`);

        const image = new Image();
        image.src = require(`../../images/tilesets/${this.sceneData.tileset.image}`);
        image.onload = () => {
            this.sprite = image;
        };
    }

    update = () => {
        this.player?.update();
    };

    draw = (ctx, frame, canvasW, canvasH) => {
        if (this.sprite) {
            const destW = canvasW / this.sceneData.tileset.canvasColumns;
            const destH = canvasH / this.sceneData.tileset.canvasRows;
            this.sceneData.layers.forEach(layer => {
                for (let i = 0; i < layer.length; i++) {
                    const tileIndex = layer[i];
                    if (tileIndex !== -1) {
                        const srcX = tileIndex % this.sceneData.tileset.srcColumns * this.sceneData.tileset.srcWidth;
                        const srcY = Math.floor(tileIndex / this.sceneData.tileset.srcColumns) * this.sceneData.tileset.srcHeight;
                        const x = i % this.sceneData.tileset.canvasColumns * destW;
                        const y = Math.floor(i / this.sceneData.tileset.canvasColumns) * destH;
                        ctx.drawImage(this.sprite,
                            srcX, srcY,
                            this.sceneData.tileset.srcWidth, this.sceneData.tileset.srcHeight,
                            x, y,
                            destW, destH
                        );
                    }
                }
            });
        }

        this.player?.draw(ctx, frame, canvasW, canvasH);
    }

    onClick = (clickEvent, onEvent) => {
        const column = Math.floor(clickEvent.xFraction * this.sceneData.tileset.canvasColumns);
        const row = Math.floor(clickEvent.yFraction * this.sceneData.tileset.canvasRows);
        const index = row * this.sceneData.tileset.canvasColumns + column;
        let event = null;
        for (let i = 0; i < this.sceneData.interactions.length; i++) {
            const interaction = this.sceneData.interactions[i];
            if (interaction.area.includes(index)) {
                event = () => { onEvent(interaction.event) };
                break;
            }
        }

        if (this.player) {
            this.player.onClick(clickEvent);
            this.player.setOnReachTarget(event);
        } else if (event) {
            event();
        }

        return;
    };
}