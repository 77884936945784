import { useEffect } from "react";
import StartBattleDialog from "./dialog/battle/startBattleDialog";
import ChatClient from "./network/chatClient";
import Scene from "./scene/scene";

const ResolutionWidth = 1536;
const ResolutionHeight = 768;

const chatClient = new ChatClient();
const scenes = {
    town: new Scene('town')
};
let currentScene = scenes.town;
let currentDialog = null;

const onInteractionEvent = (event) => {
    switch (event) {
        case 'battleDialog':
            currentDialog = new StartBattleDialog(() => currentDialog = null);
            break;
        case 'battleHouse':
            currentScene = new Scene('battleHouse');
            break;
        default:
            throw new Error(`Unhandled interaction event ${event}`);
    }
}

const update = () => {
    currentScene?.update();
    currentDialog?.update();
};

const onClick = (clickEvent) => {
    if (currentDialog) {
        currentDialog.onClick(clickEvent, onInteractionEvent);
    } else {
        currentScene?.onClick(clickEvent, onInteractionEvent);
    }

    //chatClient.sendMessage("En chattklient klickade på " + x + "," + y);
}

const draw = (ctx, frame) => {
    currentScene?.draw(ctx, frame, ResolutionWidth, ResolutionHeight);
    currentDialog?.draw(ctx, frame, ResolutionWidth, ResolutionHeight);
}

const useGameEngine = () => {
    useEffect(() => {
        chatClient.connect();
        return () => { chatClient.close() }
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            update();
        }, 60);
        return () => { clearInterval(timer) }
    }, []);

    return [
        draw,
        onClick,
        ResolutionWidth,
        ResolutionHeight
    ];
}

export default useGameEngine