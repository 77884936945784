import { useCallback, useEffect, useState } from 'react';
import NetworkManager from '../../gamelogic/network/networkManager';
import Game from '../game/Game';
import Loading from '../loading/Loading';
import Login from '../login/Login';
import './App.css';

const networkManager = new NetworkManager();

const App = () => {

  const [user, setUser] = useState(null);
  const [hasValidated, setHasValidated] = useState(false);

  const userListener = useCallback(u => {
    setUser(u);
  }, []);

  useEffect(() => {
    networkManager.setUserListener(userListener);
  }, [userListener]);

  useEffect(() => {
    const validate = async () => {
      await networkManager.validateToken();
      setHasValidated(true);
    }
    if (!user && !hasValidated) {
      validate();
    }
  }, [hasValidated, user]);

  return (
    <div className="App">
      {(user === null && !hasValidated) && (
        <Loading />
      )}
      {(user === null && hasValidated) && (
        <Login />
      )}
      {user !== null && (
        <Game />
      )}
    </div>
  );
}

export default App;
