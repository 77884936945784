import './Game.css';
import useCanvas from './useCanvas';
import useGameEngine from '../../gamelogic/useGameEngine';

const Game = () => {
    const [gameEngineDraw, onClick, resolutionWidth, resolutionHeight] = useGameEngine();

    const [canvasRef, elementSize] = useCanvas(
        gameEngineDraw,
        onClick,
        resolutionWidth,
        resolutionHeight
    );

    return (<>
        <div className={"canvas-container"}>
            <canvas width={resolutionWidth} height={resolutionHeight} style={{ width: elementSize.width, height: elementSize.height }} id='canvas' ref={canvasRef} />
        </div>
    </>);
}

export default Game;
