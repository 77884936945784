import NetworkManager from '../../network/networkManager';
import Button from '../button';
import Dialog from '../dialog';
import TextView from '../textView';

const networkManager = new NetworkManager();

export default class StartBattleDialog extends Dialog {

    title = "Start Battle"

    constructor(onClose) {
        super(10, 10, onClose);

        this.bodyView = new TextView("Will check for battle");
        this.addView(this.bodyView);

        this.#getBattleData();
    }

    #getBattleData = async () => {
        let battleData = await networkManager.battleData();
        console.log("battleData", battleData);
        if (battleData.battle === null) {
            this.bodyView.setText("Battle not found");
            this.addView(new Button("Start new battle", async (onEvent) => {
                battleData = await networkManager.initiateBattle(1);
                this.onClose();
                onEvent("battleHouse");
            }));
        } else {
            this.bodyView.setText(`There is an ongoing battle`);
            this.addView(new Button("Continue battle", (onEvent) => {
                this.onClose();
                onEvent("battleHouse");
            }));
        }
    }

}