import React, { useEffect, useState } from 'react';
import useCanvas from '../game/useCanvas';
import './LevelEditor.css';

const townJson = require('../../gamelogic/scene/town.json');

const resolutionWidth = 576;
const resolutionHeight = 288;
const sizeMod = 2;

const LevelEditor = () => {
    const [levelData, setLevelData] = useState(townJson);
    const [sprite, setSprite] = useState(null);
    const [selectedTile, setSelectedTile] = useState(null);
    const [currentLayer, setCurrentLayer] = useState(0);
    const [hoverIndex, setHoverIndex] = useState(null);

    useEffect(() => {
        const image = new Image();
        image.src = require(`../../images/tilesets/${levelData.tileset.image}`);
        image.onload = () => {
            setSprite(image);
        };
    }, [levelData.tileset.image])

    const canvasColumnsChange = (columnCount) => {
        const newLevelData = { ...levelData, tileset: { ...levelData.tileset, canvasColumns: columnCount } };
        if (columnCount > levelData.tileset.canvasColumns) {
            newLevelData.layers.forEach(layer => {
                for (let i = 0; i < layer.length; i++) {
                    const column = i % columnCount;
                    if (column >= levelData.tileset.canvasColumns) {
                        layer.splice(i, 0, -1);
                    }
                }
            })
        }
        if (columnCount < levelData.tileset.canvasColumns) {
            newLevelData.layers.forEach(layer => {
                for (let i = layer.length - 1; i >= 0; i--) {
                    const column = i % levelData.tileset.canvasColumns;
                    if (column >= columnCount) {
                        layer.splice(i, 1);
                    }
                }
            })
        }
        setLevelData(newLevelData);
    }

    const canvasRowsChange = (rowCount) => {
        const newLevelData = { ...levelData, tileset: { ...levelData.tileset, canvasRows: rowCount } };
        if (rowCount < levelData.tileset.canvasRows) {
            const firstIndexToRemove = levelData.tileset.canvasColumns * rowCount;
            newLevelData.layers.forEach(layer => {
                if (layer.length > firstIndexToRemove) {
                    layer.splice(firstIndexToRemove, layer.length - firstIndexToRemove);
                }
            })
        }
        setLevelData(newLevelData);
    }

    const draw = (ctx, frame) => {
        const canvasW = canvasRef.current.width;
        const canvasH = canvasRef.current.height;
        ctx.clearRect(0, 0, canvasW, canvasH)

        const w = resolutionWidth / levelData.tileset.canvasColumns * sizeMod;
        const h = resolutionHeight / levelData.tileset.canvasRows * sizeMod;
        if (sprite) {
            levelData.layers.forEach((layer, layerIndex) => {
                ctx.globalAlpha = layerIndex === currentLayer ? 1.0 : 0.4;
                for (let i = 0; i < layer.length; i++) {
                    const tileIndex = layer[i];
                    if (tileIndex !== -1) {
                        const srcX = tileIndex % levelData.tileset.srcColumns * levelData.tileset.srcWidth;
                        const srcY = Math.floor(tileIndex / levelData.tileset.srcColumns) * levelData.tileset.srcHeight;
                        const x = i % levelData.tileset.canvasColumns * w;
                        const y = Math.floor(i / levelData.tileset.canvasColumns) * h;
                        ctx.drawImage(sprite,
                            srcX, srcY,
                            levelData.tileset.srcWidth, levelData.tileset.srcHeight,
                            x, y,
                            w, h
                        );
                    }
                }
            });
        }

        ctx.globalAlpha = 1.0;
        ctx.lineWidth = 0.5;
        for (let x = w; x < canvasW; x += w) {
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, canvasH);
            ctx.stroke();
        }
        for (let y = h; y < canvasH; y += h) {
            ctx.beginPath();
            ctx.moveTo(0, y);
            ctx.lineTo(canvasW, y);
            ctx.stroke();
        }
    }

    const onMove = (event, leftMouseButton, rightMouseButton) => {
        const column = Math.floor(event.xFraction * levelData.tileset.canvasColumns);
        const row = Math.floor(event.yFraction * levelData.tileset.canvasRows);
        const index = row * levelData.tileset.canvasColumns + column;
        setHoverIndex(index);
        if (leftMouseButton) {
            if (selectedTile === null) return;
            const newData = { ...levelData };
            while (!newData.layers[currentLayer]) { newData.layers.push([]) }
            while (!newData.layers[currentLayer][index]) { newData.layers[currentLayer].push(-1) }
            newData.layers[currentLayer][index] = selectedTile;
            setLevelData(newData);
        } else if (rightMouseButton) {
            const newData = { ...levelData };
            newData.layers[currentLayer][index] = -1;
            setLevelData(newData);
        }
    }

    const spritesheetDraw = (ctx, frame) => {
        const canvasW = spritesheetCanvasRef.current.width;
        const canvasH = spritesheetCanvasRef.current.height;
        ctx.clearRect(0, 0, canvasW, canvasH)

        if (sprite) {
            ctx.drawImage(sprite,
                0, 0,
                levelData.tileset.srcWidth * levelData.tileset.srcColumns, levelData.tileset.srcHeight * levelData.tileset.srcRows,
                0, 0,
                canvasW, canvasH
            );
        }

        const w = canvasW / levelData.tileset.srcColumns;
        const h = canvasH / levelData.tileset.srcRows;
        ctx.lineWidth = 0.2;
        for (let x = w; x < canvasW; x += w) {
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, canvasH);
            ctx.stroke();
        }
        for (let y = h; y < canvasH; y += h) {
            ctx.beginPath();
            ctx.moveTo(0, y);
            ctx.lineTo(canvasW, y);
            ctx.stroke();
        }

        if (selectedTile !== null) {
            const selectedX = selectedTile % levelData.tileset.srcColumns * levelData.tileset.srcWidth;
            const selectedY = Math.floor(selectedTile / levelData.tileset.srcColumns) * levelData.tileset.srcHeight;
            ctx.lineWidth = 2;
            ctx.strokeRect(selectedX, selectedY, levelData.tileset.srcWidth, levelData.tileset.srcHeight)
        }
    }

    const spritesheetOnClick = (clickEvent) => {
        const canvasW = spritesheetCanvasRef.current.width;
        const canvasH = spritesheetCanvasRef.current.height;
        const column = Math.floor(clickEvent.x / canvasW * levelData.tileset.srcColumns);
        const row = Math.floor(clickEvent.y / canvasH * levelData.tileset.srcRows);
        setSelectedTile(row * levelData.tileset.srcColumns + column);
    }

    const selectedTileDraw = (ctx, frame) => {
        const canvasW = selectedTileCanvasRef.current.width;
        const canvasH = selectedTileCanvasRef.current.height;
        ctx.clearRect(0, 0, canvasW, canvasH);

        if (sprite && selectedTile !== null) {
            const selectedX = selectedTile % levelData.tileset.srcColumns * levelData.tileset.srcWidth;
            const selectedY = Math.floor(selectedTile / levelData.tileset.srcColumns) * levelData.tileset.srcHeight;
            ctx.drawImage(sprite,
                selectedX, selectedY,
                levelData.tileset.srcWidth, levelData.tileset.srcHeight,
                0, 0,
                canvasW, canvasH
            );
        }
    }

    const [canvasRef] = useCanvas(draw, null, resolutionWidth, resolutionHeight, onMove);
    const [spritesheetCanvasRef] = useCanvas(spritesheetDraw, spritesheetOnClick, levelData.tileset.srcColumns * levelData.tileset.srcWidth, levelData.tileset.srcRows * levelData.tileset.srcHeight);
    const [selectedTileCanvasRef] = useCanvas(selectedTileDraw, null, 1, 1);

    return (
        <div className="level-editor-container">
            <div className="panel">
                <table>
                    <tbody>
                        <tr>
                            <td colSpan={4}>Canvas</td>
                        </tr>
                        <tr>
                            <td>Columns</td>
                            <td>
                                <input
                                    type="number"
                                    className="input-field"
                                    value={levelData.tileset.canvasColumns}
                                    onChange={(e) => canvasColumnsChange(+e.target.value)}
                                />
                            </td>
                            <td>Rows</td>
                            <td>
                                <input
                                    type="number"
                                    className="input-field"
                                    value={levelData.tileset.canvasRows}
                                    onChange={(e) => canvasRowsChange(+e.target.value)}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={4}>Spritesheet</td>
                        </tr>
                        <tr>
                            <td>Columns</td>
                            <td>
                                <input
                                    type="number"
                                    className="input-field"
                                    value={levelData.tileset.srcColumns}
                                    onChange={(e) => setLevelData({ ...levelData, tileset: { ...levelData.tileset, srcColumns: +e.target.value } })}
                                />
                            </td>
                            <td>Rows</td>
                            <td>
                                <input
                                    type="number"
                                    className="input-field"
                                    value={levelData.tileset.srcRows}
                                    onChange={(e) => setLevelData({ ...levelData, tileset: { ...levelData.tileset, srcRows: +e.target.value } })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Source width</td>
                            <td>
                                <input
                                    type="number"
                                    className="input-field"
                                    value={levelData.tileset.srcWidth}
                                    onChange={(e) => setLevelData({ ...levelData, tileset: { ...levelData.tileset, srcWidth: +e.target.value } })}
                                />
                            </td>
                            <td>Source height</td>
                            <td>
                                <input
                                    type="number"
                                    className="input-field"
                                    value={levelData.tileset.srcHeight}
                                    onChange={(e) => setLevelData({ ...levelData, tileset: { ...levelData.tileset, srcHeight: +e.target.value } })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Source</td>
                            <td colSpan={3}>
                                <select
                                    className="input-field"
                                    value={levelData.tileset.image}
                                    onChange={(e) => setLevelData({ ...levelData, tileset: { ...levelData.tileset, image: e.target.value } })}
                                >
                                    <option value="environment.png">environment.png</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <canvas
                                    width={50}
                                    height={50}
                                    ref={selectedTileCanvasRef} />
                            </td>
                            <td colSpan={3}>
                                <canvas
                                    width={levelData.tileset.srcColumns * levelData.tileset.srcWidth}
                                    height={levelData.tileset.srcRows * levelData.tileset.srcHeight}
                                    ref={spritesheetCanvasRef} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>
                                    <input type="radio" name="z-index"
                                        checked={currentLayer === 0}
                                        onChange={() => { setCurrentLayer(0) }} />
                                    Back
                                </label>
                            </td>
                            <td>
                                <label>
                                    <input type="radio" name="z-index" value="1"
                                        checked={currentLayer === 1}
                                        onChange={() => { setCurrentLayer(1) }} />
                                    Middle
                                </label>
                            </td>
                            <td>
                                <label>
                                    <input type="radio" name="z-index" value="2"
                                        checked={currentLayer === 2}
                                        onChange={() => { setCurrentLayer(2) }} />
                                    Front
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="canvas-container">
                <canvas
                    width={resolutionWidth * sizeMod}
                    height={resolutionHeight * sizeMod}
                    ref={canvasRef} />
                <div>
                    <span>{hoverIndex}</span>
                    <input type="text" className="input-field" value={JSON.stringify(levelData)} readOnly />
                </div>
            </div>
        </div>
    );
};

export default LevelEditor;
