export default class ChatClient {

    constructor() {
        if (ChatClient.instance) {
            return ChatClient.instance = this;
        }
        ChatClient.instance = this;
    }

    connect = () => {
        if (!this.ws) {
            this.ws = new WebSocket('wss://stoftchat.drewi.se');

            this.ws.onopen = () => {
                console.log('Ansluten till chattserver');
            };

            this.ws.onmessage = (message) => {
                console.log(`Mottaget meddelande: ${message.data}`);
            };

            this.ws.onclose = () => {
                console.log('Anslutningen stängd');
                this.ws = null;
            };
        }
    }

    close = () => {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    }

    sendMessage = (message) => {
        if (this.ws?.readyState) {
            const data = message.toString().trim();
            this.ws.send(data);
        }
    }

}