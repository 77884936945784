import TextDrawer from "../text/textDrawer";
import View from "./view";

export default class TextView extends View {

    rowSpacing = 0;

    constructor(text, id) {
        super(id);
        this.setText(text);
    }

    setText(text) {
        this.text = text;
    }

    draw(ctx, x, y, maxW) {
        const size = TextDrawer.drawText(ctx, this.text, x, y, maxW, this.rowSpacing);
        super.setBounds(x, y, size.w, size.h);
        return size;
    };

    measureText(ctx, maxW) {
        return TextDrawer.measure(ctx, this.text, maxW, this.rowSpacing);
    };
}