export default class View {

    boundingBox = { x: 0, y: 0, w: 0, h: 0 };

    constructor(id) {
        this.id = id;
    }

    setBounds(x, y, w, h) {
        this.boundingBox.x = x;
        this.boundingBox.y = y;
        this.boundingBox.w = w;
        this.boundingBox.h = h;
    }

    isInside(x, y) {
        return (
            x >= this.boundingBox.x &&
            x <= this.boundingBox.x + this.boundingBox.w &&
            y >= this.boundingBox.y &&
            y <= this.boundingBox.y + this.boundingBox.h
        );
    }

}