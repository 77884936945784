import TextView from "./textView";

export default class Button extends TextView {

    padding = 8;
    marginTop = 8;
    borderColor = '#502020';
    fillColor = '#704000';

    constructor(text, clickListener, id) {
        super(text, id);
        this.clickListener = clickListener;
    }

    draw = (ctx, x, y, maxW) => {
        const size = super.measureText(ctx, maxW);
        size.w += this.padding * 2;
        size.h += this.padding * 2;
        const buttonY = y + this.marginTop + this.padding / 2;

        ctx.fillStyle = this.fillColor;
        ctx.fillRect(x, buttonY, size.w, size.h);
        ctx.strokeStyle = this.borderColor;
        ctx.lineWidth = 2;
        ctx.strokeRect(x, buttonY, size.w, size.h);

        super.draw(ctx, x + this.padding, y + this.padding + this.marginTop, maxW - this.padding * 2);

        super.setBounds(x, buttonY, size.w, size.h);

        size.h += this.marginTop;
        return size;
    };

    onClick = (clickEvent, onEvent) => {
        this.clickListener(onEvent);
        return true;
    };

}