import playerImgSrc from '../../images/characters/player.png';
import Updatable from '../updatable';

export default class Player extends Updatable {
    srcW = 48;
    srcH = 48;
    w = 128;
    h = 128;
    anchorX = this.w * 0.5;
    anchorY = this.h * 0.75;
    speed = 50;

    constructor() {
        super();
        this.x = 0;
        this.y = 0;
        this.targetX = 0;
        this.targetY = 0;

        const image = new Image();
        image.src = playerImgSrc;
        image.onload = () => {
            this.sprite = image;
        };
    }

    draw = (ctx, frame) => {
        if (this.x !== this.targetX || this.y !== this.targetY) {
            const dx = this.targetX - this.x;
            const dy = this.targetY - this.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            const xv = (dx / distance) * this.speed;
            const yv = (dy / distance) * this.speed;

            if (Math.abs(dx) > Math.abs(xv)) {
                this.x = this.x + xv;
            } else {
                this.x = this.targetX;
            }
            if (Math.abs(dy) > Math.abs(yv)) {
                this.y = this.y + yv;
            } else {
                this.y = this.targetY;
            }
        }
        super.draw(ctx, frame);
    };

    update = () => {
        if (this.onReachTargetEvent && this.x === this.targetX && this.y === this.targetY) {
            const e = this.onReachTargetEvent;
            this.onReachTargetEvent = null;
            e();
        }
    };

    onClick = (clickEvent) => {
        return this.moveTo(clickEvent.x, clickEvent.y);
    };

    moveTo = (x, y, animate = true) => {
        const translatedX = x - this.anchorX;
        const translatedY = y - this.anchorY;
        this.targetX = translatedX;
        this.targetY = translatedY;
        if (!animate) {
            this.x = translatedX;
            this.y = translatedY;
        }
    }

    setOnReachTarget = (onReachTargetEvent) => {
        this.onReachTargetEvent = onReachTargetEvent;
    }
}