export default class Updatable {
    sprite = null;
    srcW = 0;
    srcH = 0;
    w = 0;
    h = 0;
    animationSpeed = 20;
    idleAnimation = [
        [0, 0],
        [48, 0],
    ];

    update() {
    };

    draw(ctx, frame) {
        if (this.sprite) {
            const animationFrame = Math.floor(frame / this.animationSpeed) % this.idleAnimation.length;
            const [srcX, srcY] = this.idleAnimation[animationFrame];
            ctx.drawImage(this.sprite, srcX, srcY, this.srcW, this.srcH, this.x, this.y, this.w, this.h);
        }
    };

    onClick(x, y) {
        console.log("Unhandled click on " + this.constructor.name);
        return false;
    };
}