export default class TextDrawer {

    static Font = "Arial";
    static TitleFontSize = 24;
    static BodyFontSize = 18;

    static drawTitle = (ctx, text, x, y, maxW, rowSpacing) => {
        ctx.font = `${TextDrawer.TitleFontSize}px ${TextDrawer.Font}`;
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        const textMeasurement = TextDrawer.#measureText(ctx, text);

        ctx.fillText(text, x, y + textMeasurement.h);

        return textMeasurement;
    }

    static measure = (ctx, text, maxW, rowSpacing) => {
        return TextDrawer.drawText(ctx, text, 0, 0, maxW, rowSpacing, true);
    }

    static drawText = (ctx, text, x, y, maxW, rowSpacing, skipDraw = false) => {
        ctx.font = `${TextDrawer.BodyFontSize}px ${TextDrawer.Font}`;
        ctx.fillStyle = "black";
        ctx.textAlign = "start";
        let textMeasurement = TextDrawer.#measureText(ctx, text);

        const strings = [];
        if (maxW && textMeasurement.w > maxW) {
            const words = text.split(' ');
            let substring = "";
            for (let i = 0; i < words.length; i++) {
                const word = words[i];
                const oldSubstring = { text: substring, measure: textMeasurement };
                substring += (substring ? " " : "") + word;
                textMeasurement = TextDrawer.#measureText(ctx, substring);
                if (textMeasurement.w > maxW) {
                    strings.push(oldSubstring);
                    substring = word;
                } else if (i === words.length - 1) {
                    strings.push({ text: substring, measure: textMeasurement });
                }
            }
        } else {
            strings.push({ text: text, measure: textMeasurement });
        }

        let drawnWidth = 0;
        let extraY = 0;
        strings.forEach(({ text, measure }) => {
            drawnWidth = Math.max(drawnWidth, measure.w);
            if (!skipDraw) {
                ctx.fillText(text, x, y + measure.h + extraY, maxW);
            }
            extraY += measure.h + rowSpacing;
        })

        return { w: drawnWidth, h: extraY - rowSpacing };
    }

    static #measureText = (ctx, text) => {
        const metrics = ctx.measureText(text);
        const fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
        // const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

        return { w: metrics.width, h: fontHeight };
    }
}