const BaseUrl = "https://stoftapi.drewi.se";

export default class NetworkManager {
    #isLoggedIn = false;

    constructor() {
        if (NetworkManager.instance) {
            return NetworkManager.instance;
        }
        NetworkManager.instance = this;
    }

    validateToken = async () => {
        const token = localStorage.getItem('accessToken');
        if (token) {

            try {
                await this.#get('validateToken', token);
                this.token = token;
                this.user = JSON.parse(localStorage.getItem('user'));
                this.#isLoggedIn = true;
                if (this.userListener) {
                    this.userListener(this.user);
                }
                return true;
            } catch (error) {
                console.error();
                return false;
            }
        }
        return false;
    }

    login = async (username, password) => {
        try {
            const requestBody = {
                username: username,
                password: password
            }
            const response = await this.#post('login', requestBody);
            this.token = response.token;
            this.user = response.user;

            localStorage.setItem('accessToken', this.token);
            localStorage.setItem('user', JSON.stringify(this.user));

            this.#isLoggedIn = true;

            if (this.userListener) {
                this.userListener(response.user);
            }

            return true;
        } catch (error) {
            return false;
        }
    }

    battleData = async () => {
        try {
            const response = await this.#get('battle');
            return response;
        } catch (error) {
            return false;
        }
    }

    initiateBattle = async (templateId) => {
        try {
            const requestBody = {
                battleTemplateId: templateId
            }
            const response = await this.#post('initiatebattle', requestBody);
            return response;
        } catch (error) {
            return false;
        }
    }

    setUserListener = (userListener) => {
        this.userListener = userListener;
        if (this.userListener && this.user) {
            this.userListener(this.user);
        }
    }

    #get = async (path, token = this.token) => {
        const options = {
            method: 'GET',
            headers: {
                'token': token
            }
        }

        return await this.#handleRequest(path, options);
    }

    #post = async (path, body, token = this.token) => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify(body)
        }

        return await this.#handleRequest(path, options);
    }

    #handleRequest = async (path, options, baseUrl = BaseUrl) => {
        const url = `${baseUrl}/${path}`;

        try {
            const response = await fetch(url, options);
            if (response.ok) {
                if (response.status === 204) {
                    return;
                } else {
                    return await response.json();
                }
            }
            const error = {
                status: response.status
            };
            if (response.status === 401 || response.status === 403) {
                this.#removeUser();
                error.message = 'No valid token';
            } else {
                error.message = `Unknown error (${response.status}): ${await response.text()}`;
            }
            throw error;
        } catch (error) {
            console.error(`${options.method} ${url} failed`, error);
            throw error;
        }
    }

    #removeUser = () => {
        this.token = null;
        this.#isLoggedIn = false;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        if (this.userListener) {
            this.userListener(null);
        }
    }

}