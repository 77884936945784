import TextDrawer from '../text/textDrawer';
import Updatable from '../updatable';

export default class Dialog extends Updatable {

    destW = 32;
    destH = 32;
    srcW = 32;
    srcH = 32;
    #views = [];
    childPaddingX = 16;
    childPaddingY = 16;
    viewSpacing = 0;

    constructor(xTiles, yTiles, onClose) {
        super();

        this.onClose = onClose;
        this.xTiles = xTiles;
        this.yTiles = yTiles;

        const image = new Image();
        image.src = require(`../../images/tilesets/menu.png`);
        image.onload = () => {
            this.sprite = image;
        };
    }

    addView = (view) => {
        this.#views.push(view);
    }

    removeView = (view) => {
        for (let i = 0; i < this.#views.length; i++) {
            if (this.#views[i] === view) {
                this.#views.splice(i, 1);
                break;
            }
        }
    }

    update = () => {
    };

    draw = (ctx, frame, canvasW, canvasH) => {
        ctx.globalAlpha = 0.75;
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, canvasW, canvasH);
        ctx.globalAlpha = 1.0;

        if (this.sprite) {
            const startX = canvasW / 2 - this.xTiles * this.destW * 0.5;
            let startY = canvasH * 0.1;
            for (let i = 0; i < this.xTiles; i++) {
                for (let j = 0; j < this.yTiles; j++) {
                    const xIndex = (i === 0 ? 0 : (i === this.xTiles - 1 ? 2 : 1));
                    const yIndex = (j === 0 ? 0 : (j === this.yTiles - 1 ? 2 : 1));
                    ctx.drawImage(this.sprite,
                        xIndex * this.srcW, yIndex * this.srcH,
                        this.srcW, this.srcH,
                        startX + i * this.destW, startY + j * this.destH,
                        this.destW, this.destH
                    );
                }
            }
            if (this.title) {
                const size = TextDrawer.drawTitle(ctx, this.title, canvasW / 2, startY + 16);
                startY += size.h + this.viewSpacing;
            }

            this.#drawChildViews(ctx, startX, startY);
        }
    };

    #drawChildViews = (ctx, startX, startY) => {
        const maxW = this.xTiles * this.destW - this.childPaddingX * 2;
        let x = this.childPaddingX + startX;
        let y = this.childPaddingY + startY;
        this.#views.forEach((view, i) => {
            const size = view.draw(ctx, x, y, maxW);
            y += size.h + this.viewSpacing;
        });
    }

    onClick = (clickEvent, onEvent) => {
        for (let i = 0; i < this.#views.length; i++) {
            const view = this.#views[i];
            if (view.isInside(clickEvent.x, clickEvent.y)) {
                if (view.onClick(clickEvent, onEvent)) {
                    break;
                }
            }
        };
    };

}