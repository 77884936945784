import { useEffect, useLayoutEffect, useRef, useState } from 'react';

let lastMovementCallback = 0;
const useCanvas = (draw, onClick, resolutionWidth, resolutionHeight, onMove, onRightClick) => {
    const canvasRef = useRef(null)
    const [elementSize, setElementSize] = useState({ width: resolutionWidth, height: resolutionHeight });

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = false;

        let frameCount = 0;
        let animationFrameId;

        const getPosition = (event) => {
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            const xScale = resolutionWidth / canvas.offsetWidth;
            const yScale = resolutionHeight / canvas.offsetHeight;
            return {
                x: Math.floor(xScale * x),
                y: Math.floor(yScale * y),
                xFraction: x / rect.width,
                yFraction: y / rect.height
            };
        }

        const canvasClick = (event) => {
            onClick(getPosition(event));
        }

        const canvasRightClick = (event) => {
            event.preventDefault();
            if (onRightClick) {
                onClick(getPosition(event));
            }
        }

        const canvasPointerMove = (event) => {
            event.preventDefault();
            const now = Date.now();
            if (now - lastMovementCallback > 15) {
                lastMovementCallback = now;
                onMove(getPosition(event), event.buttons === 1, event.buttons === 2);
            }
        }

        const render = () => {
            frameCount++;
            draw(ctx, frameCount);
            animationFrameId = window.requestAnimationFrame(render);
        }
        render();

        canvas.addEventListener('ctxmenu', canvasRightClick);
        if (onClick) canvas.addEventListener('click', canvasClick);
        if (onMove) canvas.addEventListener('pointermove', canvasPointerMove);
        if (onMove) canvas.addEventListener('pointerdown', canvasPointerMove);
        return () => {
            window.cancelAnimationFrame(animationFrameId);
            canvas.removeEventListener('ctxmenu', canvasRightClick);
            if (onClick) canvas.removeEventListener('click', canvasClick);
            if (onMove) canvas.removeEventListener('pointermove', canvasPointerMove);
            if (onMove) canvas.removeEventListener('pointerdown', canvasPointerMove);
        }
    }, [draw, onClick, onMove, onRightClick, resolutionHeight, resolutionWidth]);

    useLayoutEffect(() => {
        const updateSize = () => {
            const scaleX = window.innerWidth / resolutionWidth;
            const scaleY = window.innerHeight / resolutionHeight;
            const scale = Math.min(scaleX, scaleY);

            setElementSize({ width: resolutionWidth * scale, height: resolutionHeight * scale });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [resolutionHeight, resolutionWidth]);

    return [canvasRef, elementSize];
}

export default useCanvas